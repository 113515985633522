<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed d-sm-none d-flex">
      <div class="headerExport">
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-container fluid>
					<v-data-table
            :headers="tableHeader"
            :items="tableData"
            class="elevation-1 v-table-middle-align"
            :single-select="false"
            show-select
            v-model="selectedCustomers"
            :loading="isCustomersDataLoading"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:top>
              <div class="d-block d-sm-flex d-md-flex d-lg-flex pa-4">
                <div class="mr-auto">
                  <p class="header-1 mb-0 pt-2">{{ $t('customers') }}</p>
                </div>
                <div class="d-sm-flex d-inline">
                  <v-text-field
                    v-model="searchText"
                    :label="$t('search_customers')"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    hide-details
                    flat
                    solo
                    outlined
                    dense
                  ></v-text-field>
                  <div class="ml-sm-2 ml-0 mt-2 mt-sm-0">
                    <v-btn
                      class="form-border text-capitalize"
                      color="primary"
                      @click="onToggleCustomerForm"
                      height="38"
                      block
                    >
                      {{ $t('add_customer') }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-divider/>
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
                <v-btn small rounded outlined :color="item.enabled ? 'success': 'error'" class="pa-1" >
                  <div v-if="item.enabled" class="d-flex align-center justify-space-around" style="min-width: 60px">
                    {{ $t('yes') }}
                    <v-icon>mdi-check-circle</v-icon>
                  </div>
                  <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                    <v-icon>mdi-minus-circle</v-icon>
                    {{ $t('no') }}
                  </div>
                </v-btn>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h6
                    v-bind="attrs"
                    v-on="on"
                    v-text="item.email"
                  />
                </template>
                <span>{{ item.phone }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    @click="onSelectEditCustomerData(item)"
                  >
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onSelectCustomerInformation(item)"
                  >
                    <v-list-item-title>{{ $t('view') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onSelectCustomerToDelete(item)"
                  >
                    <v-list-item-title class="red--text">{{ $t('delete') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-alert>
                <h4>{{ $t('no_data') }}</h4>
                <v-btn text small @click="onToggleCustomerForm" color="#0889a0" class="mb-2 font-weight-bold py-4 text-capitalize">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('add_customer') }}
                </v-btn>
              </v-alert>
            </template>
            <template v-slot:foot>
              <tfoot>
                <tr>
                  <td colspan="7" class="text-right">
                    <div class="d-flex justify-center justify-sm-end">
                      <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                      <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{ (pagination.total || 0).toLocaleString() }} <span class="">{{ $t('records') }}</span></h6>
                      <v-spacer/>
                      <div class="select-page-limit">
                        <v-select
                          v-model="pageLimit"
                          :items="[10, 20, 25]"
                          label="Per page"
                          hide-details
                          dense
                          flat
                          solo
                          @change="onChangePageLimit"
                        ></v-select>
                      </div>
                      <pagination
                        :total="pagination.total_pages || 1"
                        :current-page="currentPage"
                        :total-visible="10"
                        @pageSelected="onPaginationClick"
                      >
                      </pagination>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-data-table>
          <v-snackbar
            timeout="2000"
            vertical
            :color="snackbarOption.color"
            v-model="showSnackbar"
            bottom
          >
            <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
          </v-snackbar>
          <customer-form :open="showFormDialog" :is-edit-mode="isEditMode" :form-values="selectedCustomerData" @toggle="onToggleCustomerForm"></customer-form>
          <customer-delete :open="showDeleteModal" :form-data="selectedDataToDelete" @toggle="onToggleDeleteCustomerForm"></customer-delete>

          <v-dialog
            v-model="showInfoModal"
            max-width="980"
            origin="top center"
            class="dialog pa-0"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title class="pa-0">
                <v-toolbar light elevation="1" class="">
                  <v-toolbar-title>{{ selectedCustomerInformation.name }}</v-toolbar-title>
                  <v-spacer></v-spacer>

                  <v-btn class="d-none d-sm-flex" color="primary" @click="onSelectEditCustomerData(selectedCustomerInformation)">
                    {{  $t('Edit') }}
                  </v-btn>
                  <v-btn text outlined class="ml-1 mr-4 d-none d-sm-flex" @click="onSelectCustomerToDelete(selectedCustomerInformation)">
                    {{ $t('delete') }}                    
                  </v-btn>
                  <v-btn icon class="d-none d-sm-flex" @click="onToggleCustomerInformationModal">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-card-title>
              <v-card-text class="py-4 pb-8">
                <v-row>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('email') }}</h6>
                    <span>{{ selectedCustomerInformation.email }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('phone') }}</h6>
                    <span>{{ selectedCustomerInformation.phone }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('currencyname') }}</h6>
                    <span>{{ currencyValue }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('website') }}</h6>
                    <span>{{ selectedCustomerInformation.website }}</span>
                  </v-col>                  
                </v-row>
                <v-row>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('reference') }}</h6>
                    <span>{{ selectedCustomerInformation.reference }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('tax_number') }}</h6>
                    <span>{{ selectedCustomerInformation.tax_number }}</span>
                  </v-col>
                  <v-col md="6" lg="6">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('address') }}</h6>
                    <span>{{ selectedCustomerInformation.address }}</span>
                  </v-col>            
                </v-row>
                <v-row>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('address_City') }}</h6>
                    <span>{{ selectedCustomerInformation.city }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('state') }}</h6>
                    <span>{{ selectedCustomerInformation.state }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('zip_code') }}</h6>
                    <span>{{ selectedCustomerInformation.zip_code }}</span>
                  </v-col>
                  <v-col md="3" lg="3" cols="12">
                    <h6 class="subtitle-1 labelcolor--text">{{ $t('country') }}</h6>
                    <span>{{ selectedCustomerInformation.country }}</span>
                  </v-col>                  
                </v-row>
              </v-card-text>
              <v-divider class="d-flex d-sm-none"/>
              <v-card-actions class="d-flex d-sm-none">
                <v-btn color="primary" @click="onSelectEditCustomerData(selectedCustomerInformation)">
                    {{  $t('Edit') }}
                  </v-btn>
                  <v-btn text outlined class="ml-1 mr-4" @click="onSelectCustomerToDelete(selectedCustomerInformation)">
                    {{ $t('delete') }}                    
                  </v-btn>
                  <v-spacer/>
                  <v-btn @click="onToggleCustomerInformationModal" elevation="0">
                    close
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
				</v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import CustomerForm from '../components/CustomerForm.vue';
import CustomerDelete from '../components/CustomerDeleteModal.vue';
import Pagination from '../components/Pagination.vue';
import { apiErrorMessage, debounce } from '@/utils/general';

export default {
  components: {
    CustomerForm,
    CustomerDelete,
    Pagination,
  },

  data() {
    return {
      showInfoModal: false,
      isCustomerTogglingProgress: false,
      isCustomersDataLoading: false,
      customerLists: [],
      page: 1,
      showDeleteModal: false,
      selectedCustomerData: {},
      selectedCustomerInformation: {},
      selectedDataToDelete: {},
      isEditMode: false,
      searchText: '',
      selectedCustomers: [],
      showFormDialog: false,
      showSnackbar: false,
			snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
      currentPage: 1,
      pageLimit: 10,
    };
  },

  created() {
    this.fetchCustomersData();
  },

  watch: {
    searchText: debounce(function() {
      this.fetchCustomersData();
    }, 300)
  },

  computed: {
    // ...mapState('accounting', ['customerLists']),
    tableData: {
      get(){
        return this.customerLists?.data || []; 
      }
    },

    tableHeader() {
      return [
        {
          text: this.$t('name'),
          value: "name",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('email'),
          value: "email",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('phone'),
          value: "phone",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('address'),
          value: "address",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('enabled'),
          value: "enabled",
          class: "text-uppercase th--text font-weight-bold"
        },
        // {
        //   text: this.$t('open_balance'),
        //   value: "enabled",
        //   class: "text-uppercase th--text font-weight-bold"
        // },
        { text: "", value: "actions", sortable: false },
      ];
    },

    pagination: {
      get() {
        return this.customerLists.data ? this.customerLists.meta.pagination : {};
      }
    },

    currencyValue: {
      get() {
        return this.selectedCustomerInformation?.currency_values ? JSON.parse(this.selectedCustomerInformation.currency_values).name : '';
      }
    }
  },

  methods: {
    ...mapActions('accounting', ['getCustomersData']),

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchCustomersData()
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchCustomersData();
    },

    async fetchCustomersData() {
      if(this.isCustomersDataLoading) {
        return;
      }
      this.isCustomersDataLoading = true;
      try {
        const data = await this.getCustomersData({
          page: this.currentPage,
          limit: this.pageLimit,
          search: this.searchText || '',
        });
        this.customerLists = data; 
        this.isCustomersDataLoading = false;
      } catch(error) {
        this.isCustomersDataLoading = false;
        apiErrorMessage(error);
      }
    },

    onToggleCustomerForm(options = {}) {
      this.isEditMode = false;
      this.selectedServiceData = {};
      this.showFormDialog = !this.showFormDialog;
      if(options.created || options.updated) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'success',
					message: options.message,
				};
				this.showSnackbar = true;
        this.showInfoModal = false;
        this.fetchCustomersData();
      }
    },

    onSelectEditCustomerData(data = {}) {
      this.selectedCustomerData = JSON.parse(JSON.stringify(data));
      this.isEditMode = true;
      this.showFormDialog = true;
    },

    onSelectCustomerToDelete(data = {}) {
      this.selectedDataToDelete = JSON.parse(JSON.stringify(data));
      this.onToggleDeleteCustomerForm();
    },

    onToggleDeleteCustomerForm(options = {}) {
      this.showDeleteModal = !this.showDeleteModal;

      if(options.deleted) {
        this.snackbarOption = {
					icon: 'mdi-delete',
					color: 'red',
					message: options.message || 'Deleted.',
				};
				this.showSnackbar = true;
        this.showInfoModal = false;

        this.fetchCustomersData();
      }
    },

    onSelectCustomerInformation(data) {
      this.selectedCustomerInformation = data;
      this.onToggleCustomerInformationModal();
    },

    onToggleCustomerInformationModal() {
      this.showInfoModal = !this.showInfoModal;
      if(!this.showInfoModal) {
        this.selectedCustomerInformation = {};
      }
    }
    
  }
};
</script>


<style lang="scss" scoped>
.vue-app {
  background: none;
}

.v-tabs {
	border-bottom: 1px solid #ddd;

	.v-tab {
		color: #0889a0;
	}
}

.container {
  background-color: none;
}

.w-100 {
  width: 100%;
}

.v-list-item {
  min-height: 36px;
}
</style>
<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
	.dialog {
		z-index: 999;
	}
  .select-page-limit {
    width: 80px;
  }
</style>
