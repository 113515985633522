<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      max-width="980"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
      <v-card :loading="isSaving">
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{ isEditMode ? $t('edit_customer') : $t('add_customer') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
					<v-container class="grey lighten-4 pa-0">
						<v-form ref="customerForm">
              <v-card
                class="pa-0 grey lighten-4"
                outlined
                tile
              >
                <v-row no-gutters>
                  <v-col md="5" cols="12" class="pa-4">
                    <v-card flat class="grey lighten-4">
                      <v-card-text>
                        <label class="form-label text-uppercase" for="formdata-name">{{ $t('profile_name') }}</label>
                        <v-text-field
                          v-model="formData.name"
                          @change="onChangeNameValue"
                          :label="$t('customer_name_input')"
                          solo
                          class="app-theme-input-border"
                          flat
                          required
                          dense
                          id="formdata-name"
                          outlined
                          :rules="[v => !!v || 'Name is required']"
                        ></v-text-field>

                        <label class="form-label text-uppercase" for="formdata-email">{{ $t('email') }}</label>
                        <v-text-field
                          v-model="formData.email"
                          solo
                          :label="$t('customer_email_input')"
                          class="app-theme-input-border"
                          flat
                          id="formdata-email"
                          outlined
                          dense
                          :rules="[v => !!v || 'Email is required']"
                        ></v-text-field>

                      <!-- <h5 class="mb-3">{{$t('contact_Details_Title')}}</h5> -->
                        <label class="form-label text-uppercase" for="formdata-phone">{{ $t('contact_phone_title') }}</label>
                        <phone-input v-model="formData.phone"></phone-input>

                        <label class="form-label text-uppercase" for="formdata-currency">{{ $t('currencyname') }}</label>
                          <v-select
                            v-model="formData.currency"
                            :items="currencyData"
                            :rules="[v => !!v || $t('field_required')]"
                            id="formdata-currency"
                            :label="$t('customer_currency_input')"
                            solo
                            flat
                            class="app-theme-input-border mb-3"
                            dense
                            outlined
                            hide-details
                          >
                            <template v-slot:prepend-inner>
                              <span class="primary--text">{{ currencySymbol }}</span>
                            </template>
                          </v-select>

                          <label class="form-label text-uppercase" for="formdata-website">{{ $t('website') }}</label>
                            <v-text-field
                              v-model="formData.website"
                              :label="$t('customer_website_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              required
                              dense
                              id="formdata-website"
                            ></v-text-field>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="7" cols="12" class="white pa-4">
                    <v-card flat class="">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-reference">{{ $t('reference') }}</label>
                            <v-text-field
                              v-model="formData.reference"
                              :label="$t('customer_reference_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              required
                              dense
                              id="formdata-reference"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-tax-number">{{ $t('tax_number') }}</label>
                            <v-text-field
                              v-model="formData.tax_number"
                              :label="$t('customer_tax_number_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              required
                              dense
                              id="formdata-tax-number"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <label class="form-label text-uppercase" for="formdata-address">{{ $t('address') }}</label>
                            <v-textarea
                              v-model="formData.address"
                              solo
                              :label="$t('customer_address_input')"
                              class="app-theme-input-border"
                              flat
                              id="formdata-address"
                              rows="2"
                              outlined
                            ></v-textarea>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-town">{{ $t('town_city') }}</label>
                            <v-text-field
                              v-model="formData.city"
                              :label="$t('customer_city_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-town"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-zip-code">{{ $t('zip_code') }}</label>
                            <v-text-field
                              v-model="formData.zip_code"
                              :label="$t('customer_zipcode_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-zip-code"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-state">{{ $t('state') }}</label>
                            <v-text-field
                              v-model="formData.state"
                              :label="$t('customer_state_input')"
                              solo
                              class="app-theme-input-border"
                              outlined
                              flat
                              dense
                              id="formdata-state"
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <label class="form-label text-uppercase" for="formdata-country">{{ $t('country') }}</label>
                            <v-autocomplete
                              v-model="formData.country"
                              :items="countryData"
                              :rules="[v => !!v || $t('field_required')]"
                              id="formdata-country"
                              :label="$t('customer_country_input')"
                              solo
                              flat
                              class="app-theme-input-border"
                              dense
                              outlined
                              prepend-inner-icon="mdi-flag-variant"
                              hide-details
                              item-text="name"
                              item-value="code"
                            >
                              <template v-slot:prepend-inner>
                                <img :src="countryFlag" height="20" width="30" v-if="countryFlag" />
                              </template>
                            </v-autocomplete>
                          </v-col>
                          
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card>
						</v-form>
					</v-container>
				</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary" :loading="isSaving" :disabled="isSaving" v-if="!isEditMode">{{  $t("save") }}</v-btn>
          <v-btn @click="onUpdateForm" class="text-capitalize btn-primary" :loading="isSaving" :disabled="isSaving" v-if="isEditMode">{{ $t("update") }}</v-btn>
					<v-btn outlined text class="text-capitalize primary--text" @click="onClose" :disabled="isSaving">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-snackbar
        timeout="2000"
        vertical
        :color="snackbarOption.color"
        v-model="showSnackbar"
        bottom
      >
      <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>

import countries from '../data/world-countries/countries/en/countries.json';
import countryflags from '../data/world-countries/flags/16x16/flags-16x16.json';
import PhoneInput from './PhoneInput.vue';

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  components: {
    PhoneInput,
  },
  props: ["open", "isEditMode", "formValues"],
	data() {
		return {
			formData: {
				name: '',
        phone: '',
        address: '',
        email: '',
        tax_number: '',
        website: '',
        city: '',
        zip_code: '',
        state: '',
        country: '',
        reference: '',
        // qbo_display_name: '',
        // qbo_title: '',
        // qbo_given_name: '',
        // qbo_middle_name: '',
        // qbo_family_name: '',
        // qbo_suffix: '',
			},
      defaultFields: {},
      isSaving: false,
      showSnackbar: false,
      snackbarOption: {},
		}
	},

	created() {
    this.defaultFields = {...this.formData};
    this.getCurrencies();
	},

	watch: {
		formValues(values) {
			if(values) {

        const {
          name,
          phone,
          address,
          email,
          tax_number,
          currency_values,
          website,
          city,
          zip_code,
          state,
          country,
          reference,
          qbo_values,
          id,
          qbo_id,
        } = values;

        const qbo = JSON.parse(qbo_values) || {};

				this.formData = {
          id,
          name,
          email,
          tax_number,
          currency: JSON.stringify(JSON.parse(currency_values)),
          phone,
          website,
          address,
          city,
          zip_code,
          state,
          country,
          reference,
          // qbo_display_name: qbo.DisplayName,
          // qbo_title: qbo.Title,
          // qbo_given_name: qbo.GivenName,
          // qbo_middle_name: qbo.MiddleName,
          // qbo_family_name: qbo.FamilyName,
          // qbo_suffix: qbo.Suffix,
          qbo_id,
        };

			} else {
				this.formData = {
				};
			}
		},
	},

  computed: {
    ...mapState('accounting', ['currencies']),
    ...mapGetters('accounting', ['isQBOEnabled']),

    currencySymbol: {
      get() {
        if(this.formData.currency) {
          const selectedCurrency = JSON.parse(this.formData.currency);
          return this.currencies.find(currency => currency.code === selectedCurrency.code)?.symbol;
        }
        return '';
      }
    },

    showDialog: {
      get() {
        return this.open;
      },
    },

    countryData: {
      get() {
        return countries.map(country => ({code: country.alpha3.toUpperCase(), name: country.name}) );
      }
    },

    countryFlag: {
      get() {
        if(this.formData.country) {
          const country = countries.find(country => country.alpha3 === this.formData.country.toLowerCase());
          return country ? countryflags[country.alpha2] : '';
        }
        return '';
      }
    },

    currencyData() {
      return this.currencies.map(currency => 
        ({ text: currency.code, value: JSON.stringify({code: currency.code, name: currency.name}) })
      );
    }

  },

  methods: {
    ...mapActions('accounting', ['createCustomerForm', 'updateCustomerForm', 'getCurrencies']),
    
    onChangeNameValue(value) {
      if(!this.formData.qbo_display_name) {
        this.$set(this.formData, 'name', value);
        // this.$set(this.formData, 'display_name', value);
      }
    },

    onClose() {
			this.formData = {
				...this.defaultFields,
			};
      this.$refs.customerForm.reset();
      this.$emit("toggle");
    },

    async onSaveForm() {
      if(this.isSaving) {
				return;
			}

			const validated = this.$refs.customerForm.validate();
			if(validated) {
				this.isSaving = true;
				try {

					const { data } = await this.createCustomerForm({...this.formData, qbo_enabled: this.isQBOEnabled,});

					const message = data.message || 'Data was successfully saved.';
					this.snackbarOption = {
						icon: 'mdi-check',
						color: 'success',
						message,
					};

					this.$refs.customerForm.reset();
          this.$emit("toggle", { created: true, message });
				} catch(error) {

					const { data } = error.response || { data: {  } };
					
					this.snackbarOption = {
						icon: 'mdi-alert-circle',
						color: 'error',
						message: data.message || "Could not save the data.",
					};

				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
    },

    async onUpdateForm() {
      if(this.isSaving) {
				return;
			}

			const validated = this.$refs.customerForm.validate();
			if(validated) {
				this.isSaving = true;
				try {

					const { data } = await this.updateCustomerForm({
            ...this.formData,
            qbo_enabled: this.isQBOEnabled,
          });

					const message = data.message || 'Data was successfully saved.';
					this.snackbarOption = {
						icon: 'mdi-check',
						color: 'success',
						message,
					};

					this.$refs.customerForm.reset();
          this.$emit("toggle", { updated: true, message });
				} catch(error) {

					const { data } = error.response || { data: {  } };
					
					this.snackbarOption = {
						icon: 'mdi-alert-circle',
						color: 'error',
						message: data.message || "Could not save the data.",
					};

				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
    },

  },
};
</script>

<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	$form-label: #819FB2;
	.dialog {
		z-index: 9999;
	}
	.form-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	hr {
		border-color: #ebf1f5;
	}

	th {
		color: $form-label;
		font-weight: bold;
	}
	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}
	.border-dashed {
		border: 1px dashed $form-label !important;
	}
  .z-index-front {
    z-index: 1;
  }
</style>
<style lang="scss">
	.app-theme-input-border{
		.v-input__slot {
			border: 1px solid #b5d0e1 !important;
		}
		label {
			color: #a3c6dd !important;
		}
	}
</style>